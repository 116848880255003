import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/common/layout'
import { publicPath, routerMode, exhcenterUrl } from '@/config'
// import { filterRoutes } from '@/utils/routes'
Vue.use(VueRouter)
export const constantRoutes = [
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404'),
    meta: {
      hidden: true,
    },
  },
]

export const asyncRoutes = [
  {
    path: '/',
    name: 'Root',
    component: Layout,
    meta: {
      title: '首页',
      levelHidden: true,
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/index'),
        meta: {
          title: '首页',
        },
      },
      {
        path: 'detail',
        name: 'Detail',
        component: () => import('@/views/detail'),
        meta: {
          hidden: true,
          title: '文章明细',
        },
      },
      {
        path: 'news',
        name: 'News',
        component: () => import('@/views/news'),
        meta: {
          title: '展会新闻',
        },
      },
      {
        path: 'agenda',
        name: 'Agenda',
        component: () => import('@/views/agenda'),
        meta: {
          title: '峰会议程',
        },
      },
      {
        path: 'electact',
        name: 'Electact',
        component: () => import('@/views/electact'),
        meta: {
          title: '推选活动',
        },
      },
      {
        path: 'files',
        name: 'Files',
        component: () => import('@/views/files'),
        meta: {
          title: '展会文件',
        },
      },
      {
        path: 'previous',
        name: 'Previous',
        component: () => import('@/views/previous'),
        meta: {
          title: '往届回顾',
          activeMenu: '/previous',
        },
      },
      {
        path: 'service',
        name: 'Service',
        component: () => import('@/views/service'),
        meta: {
          title: '展会服务',
        },
      },
      {
        path: 'contactus',
        name: 'Contactus',
        component: () => import('@/views/contactus'),
        meta: {
          title: '联系我们',
          activeMenu: '/contactus',
        },
      },
      {
        path: exhcenterUrl,
        name: 'Exhcenter',
        meta: {
          title: '展商中心',
          target: '_blank',
        },
      },
      // {
      //   path: visiterUrl,
      //   name: 'Visitcenter',
      //   meta: {
      //     title: '观众中心',
      //     target: '_blank',
      //   },
      // },
    ],
  },
  {
    path: '*',
    redirect: '/404',
    meta: {
      hidden: true,
    },
  },
]

const router = createRouter()

function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route
  })
}

export function resetRouter(routes = constantRoutes) {
  routes.map((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children)
    }
  })
  router.matcher = createRouter(routes).matcher
}

function createRouter(routes = constantRoutes) {
  console.log('createRouter', routes)
  return new VueRouter({
    base: publicPath,
    mode: routerMode,
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: routes,
  })
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
