const cli = require('./cli.config')
// const setting = require('./setting.config')
// const theme = require('./theme.config')
const network = require('./net.config')
module.exports = {
  ...cli,
  // ...setting,
  // ...theme,
  ...network,
}
