const state = {
  banner: 'banner.png',
  homeTopUrl: 'hometop.jpg',
  ossBaseUrl: 'https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/',
  itemTipUrl: 'itemtip.png',
}
const mutations = {}
const actions = {}
const getters = {
  banner: (state) => state.ossBaseUrl + state.banner,
  homeTopUrl: (state) => state.ossBaseUrl + state.homeTopUrl,
  ossBaseUrl: (state) => state.ossBaseUrl,
  itemTipUrl: (state) => state.ossBaseUrl + state.itemTipUrl,
}
export default { state, getters, mutations, actions }
