export function rdv(url) {
  return url ? url + `?v=${Math.random()}` : url
}
export function DealContent(content) {
  if (!content) return content
  return content.replace(
    /<img[^>]*[^class="(\S*)"]*src="([^"]*)"[^>]*>/gi,
    '<img src="$1" style="width: 100%; height: auto;display:block;" />'
  )
}

export function SetLogoPathNames(article) {
  let result = []
  if (article && article.LogoPath && article.OssImagePath) {
    let names = article.LogoPath.split(']')
    names.map((n) => {
      return result.push({ images: article.OssImagePath + n })
    })
  }
  return result
}
export function formatDate(data) {
  let res = {}
  try {
    let d = new Date(Date.parse(data.replace(/-/g, '/')))
    res.year = d.getFullYear()
    res.month = d.getMonth() + 1
    res.day = d.getDate()
    res.time = d.getTime()
    res.date =
      d.getFullYear() +
      '-' +
      (res.month < 10 ? '0' + res.month : res.month) +
      '-' +
      (res.day < 10 ? '0' + res.day : res.day)
  } catch (e) {
    console.log(e)
  }
  return res
}
export function sortbydate(array, order) {
  try {
    return array.sort((a, b) => {
      if (order == 'asc') {
        return b.formatdate.time - a.formatdate.time
      }
      return a.formatdate.time - b.formatdate.time
    })
  } catch {
    return array
  }
}
