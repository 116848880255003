<template>
  <div class="list-wrapper">
    <div v-if="title" class="tip-x">
      <tip-ete :title="title" />
    </div>
    <el-row class="list" :gutter="20">
      <template v-for="item in list">
        <el-col
          :key="item.ArticleID"
          class="list-item-wrapper"
          :md="6"
          :sm="8"
          :xs="12"
        >
          <div class="list-item" @click="articleClick(item)">
            <div class="list-media img-hover">
              <img
                v-if="item.OssImagePath && item.LogoPath"
                class=""
                :src="item.OssImagePath + item.LogoPath"
              />
            </div>
            <div class="list-content">
              <div class="list-right-top">
                <div class="list-title txt-two-line">{{ item.Title }}</div>
                <div class="list-text txt-two-line">
                  {{ item.Abstract || '敬请期待' }}
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </template>
    </el-row>
    <div v-if="showMore" class="list-more">
      <div class="more">
        <div class="tool" @click="moreEvent()">
          <div class="txt">更多</div>
          <img
            src="https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/more.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // 横排
  export default {
    name: 'NewList',
    props: {
      list: {
        type: Array,
        default() {
          return []
        },
      },
      title: {
        type: String,
        default: '',
      },
      showMore: {
        type: Boolean,
        default: true,
      },
      stype: {
        type: String,
        default: null,
      },
      onlyShowCollected: {
        type: Boolean,
        default: false,
      },
      showCollectCallBack: {
        type: Boolean,
        default: false,
      },
      titleColor: {
        type: String,
        default: '#FFFFFF',
      },
    },
    data() {
      return {}
    },
    methods: {
      articleClick(item) {
        this.$emit('articleClick', item)
      },
      moreEvent() {
        if (!this.stype) return
        this.$emit('moreEvent', {
          title: this.title,
          stype: this.stype,
        })
      },
    },
  }
</script>

<style lang="scss">
  .list-wrapper {
    .list-more {
      padding: 20px 5px;
    }
    .list {
      .list-item-wrapper {
        // border: 1px solid #000000;
        border-radius: 8px;
        padding: 0;
        margin-top: 20px;
        .list-item {
          width: 100%;
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          background-color: #ffffff;
          .list-media {
            width: 100%;
            img {
              object-fit: cover;
              width: 100%;
              height: 17rem;
              border-radius: 8px 8px 0 0;
              display: block;
            }
          }
          .list-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: $font20;
            .list-right-top {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              text-align: left;
              .list-title {
                padding: 0;
                font-weight: bold;
                margin-bottom: 10px;
                font-size: 16px;
                height: 42px;
              }
              .list-text {
                font-size: 14px;
                height: 36px;
              }
            }
          }
        }
      }
    }
  }
</style>
