<template>
  <div class="agenda-list-container">
    <el-row class="agenda-list-wrapper" :gutter="20">
      <el-col
        v-for="(pitem, pindex) in list"
        :key="pindex"
        :md="12"
        :sm="12"
        :xs="24"
      >
        <div
          v-for="(item, index) in pitem.list"
          :key="index"
          class="agenda-item"
        >
          <div class="date">
            <i class="el-icon-date"></i>
            <span v-if="item.menuarticle" class="txt">
              {{ item.menuarticle.Title }}
              <span v-show="item.menuarticle.Abstract" class="subtxt">
                {{ item.menuarticle.Abstract }}
              </span>
            </span>
            <span v-else class="txt">{{ item.date }}</span>
          </div>
          <div v-if="item.newlist && item.newlist.length" class="list">
            <div
              v-for="(item1, index1) in item.newlist"
              :key="index1"
              class="item"
            >
              <div v-show="item1.ReleaseTimeText" class="datetime">
                <div class="dian"></div>
                {{ item1.ReleaseTimeText }}
              </div>
              <div class="subitem">
                <div v-for="(item2, index2) in item1.list" :key="index2">
                  <div class="txt">
                    {{ item2.Abstract }}
                    <div v-show="item2.Address" class="address">
                      <i>地点：{{ item2.Address }}</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  export default {
    name: 'AgendaList',
    props: {
      list: {
        type: Array,
        default() {
          return []
        },
      },
    },
    methods: {},
  }
</script>
<style lang="scss">
  .agenda-list-container {
    .agenda-list-wrapper {
      .agenda-item {
        margin-top: 20px;
        .date {
          background-color: #fff;
          height: 40px;
          font-size: 20px;
          color: #ca3935;
          line-height: 40px;
          text-align: left;
          padding: 0px 15px;
          .txt {
            margin-left: 10px;
          }
          .subtxt {
            font-size: 14px;
          }
        }
        .list {
          padding: 0;
          margin: 0;

          .item {
            color: #fff;
            display: flex;
            margin-top: 10px;
            align-items: baseline;
            .datetime {
              width: 110px;
              display: flex;
              align-items: center;
              .dian {
                width: 5px;
                height: 5px;
                border-radius: 50%;
                border: 1px solid #fff;
                margin-right: 5px;
                background: #fff;
              }
            }
            .subitem {
              flex: 1;

              .txt {
                font-size: 14px;
                background: rgba(255, 255, 255, 0.25);
                padding: 15px;
                width: 100%;
                text-align: left;
                padding: 15px;
                margin-top: 10px;
                white-space: pre-line;
                .address {
                  padding: 10px 0px;
                  // font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
