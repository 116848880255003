<template>
  <div class="site-app-main">
    <section>
      <keep-alive>
        <router-view />
      </keep-alive>
    </section>
    <site-footer />
  </div>
</template>
<script>
  export default {
    name: 'SiteAppMain',
  }
</script>
