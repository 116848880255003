<template>
  <div class="image-list-contianer">
    <el-row :gutter="20">
      <el-col
        v-for="(item, index) in list"
        :key="index"
        class="image-list-wrapper"
        :md="4"
        :sm="6"
        :xs="12"
      >
        <div class="image-list-item" @click="itemClick(item)">
          <img v-if="item.ImageUrl" :src="item.ImageUrl" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  export default {
    name: 'ImageList',
    props: {
      list: {
        type: Array,
        default() {
          return []
        },
      },
    },
    methods: {
      itemClick(item) {
        if (item.Href) {
          window.open(item.Href)
        }
        // this.$emit('itemClick', item)
      },
    },
  }
</script>
<style lang="scss">
  .image-list-contianer {
    .image-list-wrapper {
      margin-top: 20px;
      .image-list-item {
        border: 1px solid #999999;
        padding: 10px;
        background: #fff;
        > img {
          object-fit: contain;
          width: 100%;
        }
      }
    }
  }
</style>
