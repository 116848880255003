<template>
  <div class="tip-x" :style="{ 'padding-left': padding + 'px' }">
    <img class="image img" :src="icon == 1 ? imageUrl : imageUrl2" />
    <div class="title" :class="{ 'title-white': icon == 2 }">{{ title }}</div>
  </div>
</template>
<script>
  export default {
    name: 'TipEte',
    props: {
      imageUrl: {
        type: String,
        default:
          'https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/tipicon.png',
      },
      padding: {
        type: String,
        default: '0',
      },
      icon: {
        type: Number,
        default: 1,
      },
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        imageUrl2:
          'https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/tipicon_white.png',
      }
    },
  }
</script>

<style lang="scss">
  .tip-x {
    width: 100%;
    display: flex;
    align-items: center;
    .title {
      font-size: $font35;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-left: 10px;
    }
    .title-white {
      color: #fff !important;
    }
    .image {
      width: $px60;
    }
  }
</style>
