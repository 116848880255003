<template>
  <div class="swiper-contianer" :class="[connameClass]">
    <div class="swiper-wrapper">
      <template v-for="(item, index) in swiperList">
        <div :key="index" class="swiper-slide">
          <img
            v-if="item.src || item.images"
            :src="(item.src || item.images) + '?t=' + t"
          />
        </div>
      </template>
    </div>
    <div class="swiper-pagination" :class="[bulletClass, nameClass]"></div>
  </div>
</template>

<script>
  import Swiper from 'swiper'
  import 'swiper/dist/css/swiper.min.css'

  export default {
    name: 'SwiperEte',
    props: {
      swiperList: {
        type: Array,
        default() {
          return []
        },
      },
      bullet: {
        type: String,
        default: 'nomal',
      },
      wiperName: {
        type: String,
        default: '',
      },
    },
    data() {
      return {}
    },
    computed: {
      t() {
        return new Date().getTime()
      },
      bulletClass() {
        return this.bullet == 'bottom' ? 'bullet-bottom' : ''
      },
      nameClass() {
        return this.wiperName ? 'swiper-pagination-' + this.wiperName : ''
      },
      connameClass() {
        return this.wiperName ? 'swiper-contianer-' + this.wiperName : ''
      },
    },
    watch: {
      swiperList: function (list) {
        console.log('watch-list', list)
        let $this = this
        this.$nextTick(() => {
          $this.initSwiper()
        })
      },
    },
    mounted() {
      let $this = this
      this.$nextTick(() => {
        $this.initSwiper()
      })
    },
    methods: {
      initSwiper() {
        new Swiper('.' + this.connameClass, {
          autoplay: 5000,
          loop: true,
          grabCursor: true,
          pagination: '.' + this.nameClass,
        })
      },
    },
  }
</script>
<style lang="scss">
  .swiper-contianer {
    position: relative;
    --swiper-theme-color: #ca3935;
    --swiper-pagination-color: #ca3935; /* 两种都可以 */
    width: 100%;
    .swiper-slide > img {
      width: 100%;
    }
  }
  .swiper-pagination-bullet-active {
    background: #ca3935;
  }
  .swiper-container-horizontal > .bullet-bottom.swiper-pagination-bullets {
    bottom: -20px;
  }
</style>
