<template>
  <div
    class="site-header clearfix"
    :class="{ 'mobile-menu-header': showMobileMenu }"
  >
    <div class="header-logo dh">
      <img v-if="banner" :src="banner | rdv" />
    </div>
    <div class="header-menu">
      <div class="mobile-menu-icon" @click="showMenu()">
        <i class="el-icon-s-operation"></i>
      </div>
      <ul class="menu-list">
        <!-- <li class="menu-item">
          <router-link to="/index">首页</router-link>
        </li> -->
        <template v-for="(route, index) in handleRoutes">
          <li
            v-if="route.meta && !route.meta.hidden"
            :key="index + route.name"
            class="menu-item"
            :class="{ active: activeMenu == route.path }"
          >
            <a @click="routeClick(route)">{{ route.meta.title }}</a>
          </li>
        </template>
      </ul>
    </div>
    <div class="header-btn-tool">
      <div class="btn-tool-reg" @click="toReg()">参会/参观注册</div>
      <!-- <div class="btn-tool-zhen">
        <a class="lang-zh" href="#" @click="tranferLang('zh')">中</a>
        |
        <a class="lang-en" href="#" @click="tranferLang('en')">EN</a>
      </div> -->
    </div>
    <div class="mobile-menu">
      <div class="menu-layer"></div>
      <nav class="menu-box">
        <div class="menu-close">
          <div class="close" @click="closemenu()">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <ul class="menu-list">
          <template v-for="(route, index) in handleRoutes">
            <li
              v-if="route.meta && !route.meta.hidden"
              :key="index + route.name"
              class="menu-item"
              :class="{ active: activeMenu == route.path }"
            >
              <a @click="routeClick(route)">{{ route.meta.title }}</a>
            </li>
          </template>
        </ul>
        <div class="content">
          <div class="btn-reg" @click="toReg()">参会/参观注册</div>
          <div class="contact-us">
            <h3>联系方式</h3>
            <div class="txt">010-5338 7586</div>
            <div class="txt">topex@topexpo.cc</div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { handleActivePath, handleMatched } from '@/utils/routes'
  import { rdv } from '@/utils/index'
  import { getMainMenu } from '@/api/site'
  import { registerUrl, routerMode } from '@/config'
  import { isExternal } from '@/utils/validate'
  export default {
    name: 'SiteHeader',
    filters: {
      rdv(url) {
        return rdv(url)
      },
    },
    data() {
      return {
        activeMenu: 'index',
        showMobileMenu: false,
        menuList: [
          // { SName: '首页', type: 'ilink', url: 'index' },
          // { SName: '展会新闻', type: 'list' },
          // { SName: '资讯发布', type: 'list' },
          // { SName: '峰会议程', type: 'list' },
          // { SName: '展会服务', type: 'olink', url: 'showsDetail' },
          // { SName: '展会文件', type: 'olink', url: 'showsfiles' },
          // { SName: '往届回顾', type: 'article' },
          // { SName: '联系我们', type: 'article' },
          // { SName: '展商中心', type: 'olink', url: '' },
          // { SName: '观众中心', type: 'olink', url: '' },
          // { SName: '防疫要求', type: 'article' },
        ],
      }
    },
    computed: {
      ...mapGetters({
        banner: 'settings/banner',
        routes: 'routes/routes',
        activeName: 'routes/activeName',
      }),
      handleRoutes() {
        return this.routes.flatMap((route) => {
          // console.log('handleRoutes', route)
          return route.meta && route.meta.levelHidden === true && route.children
            ? route.children
            : route
        })
      },
      menudeallist() {
        let result = [
          { SName: '首页', SNType: 'ilink', link: 'index', qk: 'index' },
          { SName: '展会新闻', SNType: 'ilink', link: 'news', qk: 'news' },
          // { SName: '资讯发布', type: 'list' ,SID: 'zxfb'},
          {
            SName: '峰会议程',
            SNType: 'ilink',
            link: 'agenda',
            code: 'zhwj',
            qk: 'VP',
          },
          {
            SName: '展会服务',
            SNType: 'ilink',
            link: 'service',
            code: 'zhfw',
            qk: 'zhfw',
          },
          {
            SName: '展会文件',
            SNType: 'ilink',
            link: 'files',
            code: 'zhwj',
            qk: 'zhwjlist',
          },
          {
            SName: '往届回顾',
            SNType: 'article',
            qk: 'zhwj',
          },
          {
            SName: '联系我们',
            SNType: 'article',
            qk: 'zhwj',
          },
        ]
        var menuAfter = [
          { SName: '展商中心', SNType: 'olink', link: '', qk: 'exh' },
          { SName: '观众中心', SNType: 'olink', link: '', qk: 'visit' },
          { SName: '防疫要求', SNType: 'article', qk: 'fyyq' },
        ]
        result = result.concat(menuAfter)
        return result
      },
    },
    // watch: {
    //   $route: function (to, from) {
    //     console.log('watch-route', from, to)
    //     // let name = to.name.toLowerCase()
    //     // if (this.menudeallist.find((item) => item.link == name)) {
    //     //   this.activeItem = name
    //     // }
    //   },
    // },
    watch: {
      $route: {
        handler(route) {
          this.activeMenu = handleActivePath(route)
          console.log('warch-route-activeMenu', this.activeMenu)
        },
        immediate: true,
      },
      activeName: {
        handler(val) {
          console.log('warch-activeName', val)
          const matched = handleMatched(this.routes, val)
          console.log('warch-activeName-matched', matched)
          this.activeMenu = matched[matched.length - 1].path
        },
      },
    },
    created() {
      // this.getMenuList()
    },
    methods: {
      closemenu() {
        this.showMobileMenu = false
      },
      showMenu() {
        this.showMobileMenu = !this.showMobileMenu
      },
      routeClick(item) {
        console.log('routeClick', item)
        this.showMobileMenu = false
        const routePath = item.path
        const target = item.meta.target
        if (target === '_blank') {
          if (isExternal(routePath)) window.open(routePath)
          else if (this.$route.fullPath !== routePath)
            routerMode === 'hash'
              ? window.open('/#' + routePath)
              : window.open(routePath)
        } else {
          if (isExternal(routePath)) window.location.href = routePath
          else if (this.$route.fullPath !== routePath) {
            this.$router.push(item.path)
          } else this.$baseEventBus.$emit('reload-router-view')
        }
      },
      menuClick(item) {
        console.log(item)
        if (item) {
          switch (item.SNType) {
            case 'ilink':
              if (item.link) {
                this.$router.push({
                  path: item.link,
                })
              }
              break
            case 'article':
              this.$router.push({
                path: 'detail',
              })
              break
            default:
              break
          }
        }
      },
      toReg() {
        window.open(registerUrl)
      },
      tranferLang(lang) {
        console.log(lang)
        this.$baseMessage('敬请期待', 'warning')
      },
      async getMenuList() {
        const res = await getMainMenu()
        if (res && res.data) {
          this.menuList = res.data
        }
      },
    },
  }
</script>
<style lang="scss">
  .mobile-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 300px;
    padding-right: 30px;
    max-width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 2000;
    .menu-layer {
      position: fixed;
      background: rgba(0, 0, 0, 0.7);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all 0.7s ease;
      transform: translateX(-100%);
    }

    .menu-box {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease;
      transform: translateX(-100%);
      background: #515a6e;
      .menu-close {
        height: 50px;
        position: relative;
        .close {
          // margin: 10px 10px 0px 0px;
          text-align: right;
          font-size: 24px;
          width: 24px;
          color: #ffffff;
          cursor: pointer;
          transition: all 0.9s ease;
          position: absolute;
          top: 10px;
          right: 10px;
          &:hover {
            transform: rotate(360deg);
          }
        }
      }
      .menu-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        width: 100%;
        .menu-item {
          position: relative;
          display: block;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          a {
            color: #fff;
            display: block;
            padding: 10px 25px;
            font-size: 15px;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
      .content {
        padding: 10px 25px;
        .contact-us {
          color: #fff;
          .txt {
            font-size: 14px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .mobile-menu-header {
    .mobile-menu {
      opacity: 1;
      visibility: visible;
      .menu-layer {
        transform: translateX(0%);
      }
      .menu-box {
        opacity: 1;
        visibility: visible;
        transform: translateX(0%);
      }
    }
  }
  .site-header {
    position: relative;
    text-align: left;
    padding: 10px;
    // height: 70px;
    background-color: $base-color-white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04); //最先写的阴影将显示在最顶层
    z-index: 100;
    width: 100%;
    .header-logo {
      float: left;
      display: block;
    }
    .header-menu {
      float: left;
      margin-left: 10px;
      .mobile-menu-icon {
        font-size: 40px;
        display: none;
        margin-top: 10px;
      }
      .menu-list {
        display: inline-block;
        list-style: none;
        padding: 0;
        margin: 0;
        .menu-item {
          padding: 5px 20px;
          // margin-left: 5px;
          float: left;
          margin-top: 15px;
          &:first-child {
            margin-left: 5px;
          }
          a {
            // transition: $base-transition-all;
            font-weight: 500;
            letter-spacing: 1px;
          }
          &:hover {
            a {
              // font-size: 16px;
              // transform: scale(1.1);
              // display: block;
              color: #ca3935;
              font-weight: 600;
            }
          }
          &.active {
            background: #ca3935;
            border-radius: 0px 14px 0px 14px;
            a {
              color: #fff;
            }
          }
        }
      }
    }
    .btn-reg,
    .btn-tool-reg {
      cursor: pointer;
      color: #fff;
      padding: 8px 10px;
      background: linear-gradient(90deg, #ca3935 0%, #bb08ab 100%);
      border-radius: 4px;
      border: 1px solid #bb08ab;
      display: block;
      text-align: center;
    }
    .btn-reg {
      display: inline-block;
    }

    .header-btn-tool {
      float: right;
      margin: 10px 20px;
      .btn-tool-reg {
        float: left;
      }
      .btn-tool-zhen {
        a {
          color: #000000;

          display: inline-block;
        }
        a.lang-zh {
          padding: 8px 5px 8px 10px;
        }
        a.lang-en {
          padding: 8px 10px 8px 5px;
        }
        float: left;
        border-radius: 4px;
        border: 1px solid #000000;
      }
    }
  }
</style>
